body,
html,
#root {
    height: 100%;
    background-color: #090909;
}

.App {
    height: 100%;
    background-image: url('./ark03.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* @media only screen and (max-width: 1024px) {
    .App {
      background-image: url('./arkside01.png');
      background-position: center;
      background-size: cover;
    }
} */

@media only screen and (max-width: 900px) {
    .App {
      background-image: url('./arksmall01.png');
      background-position: top 0px;
      background-size: cover;
    }
}

.Banner {
    background-image: url('./logo.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}